import { defineNuxtPlugin } from '#app'

class VisibleOnScroll {
  constructor() {
    this.els = []
    this.callbacks = []

    this.init = this.init.bind(this)
    this.clear = this.clear.bind(this)
    this.setCallback = this.setCallback.bind(this)
  }

  init() {
    setTimeout(() => {
      this.defaults = {
        treshold: window.innerHeight * 0.8,
        className: 'visible'
      }

      const els = document.querySelectorAll('[data-visible-on-scroll]')

      for (let i = 0; i < els.length; i++) {
        this.els.push(els[i])
      }

      window.addEventListener('scroll', this.handleScroll.bind(this))
    }, 500)
  }

  clear() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
    this.els = []
    this.callbacks = []
  }

  setCallback(name, func, once) {
    this.callbacks.push({ name, func, once })
  }

  getCallback(name) {
    if (typeof name === 'string') {
      const c = this.callbacks.find((cb) => cb.name == name)
      if (c) return c
    }

    return false
  }

  handleScroll() {
    for (let i = 0; i < this.els.length; i++) {
      const el = this.els[i]

      if (typeof el.dataset.visibleOnScroll === 'string') {
        const args = el.dataset.visibleOnScroll.split(',')
        const className = args[0] ? args[0] : this.defaults.className
        const treshold = args[1] ? parseFloat(args[1]) * window.innerHeight : this.defaults.treshold

        if (this.isVisible(el, treshold)) {
          const isActive = el.classList.contains(className)
          if (!isActive) {
            el.classList.add(className)
          }

          const callback = this.getCallback(el.dataset.visibleCallback)
          if (typeof callback === 'object') {
            if (!isActive || !callback.once) {
              if (callback.func) callback.func()
            }
          }
        }
      }
    }
  }

  isVisible(el, treshold = this.defaults.treshold) {
    const p = el.getBoundingClientRect()

    if (p.top < treshold && p.bottom > 0) return true

    return false
  }
}

const vos = new VisibleOnScroll()

// export default ({ app }, inject) => {
//   inject('initVos', () => vos.init())
//   inject('clearVos', () => vos.clear())
//   inject('setVosCallback', (name, func, once = true) => vos.setCallback(name, func, once))
// }

export default defineNuxtPlugin(() => {
  return {
    provide: {
      initVos: () => vos.init(),
      clearVos: () => vos.clear(),
      setVosCallback: (name, func, once = true) => vos.setCallback(name, func, once)
    }
  }
})
