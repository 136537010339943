export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1 user-scalable=0"},{"hid":"image","name":"image","content":"/meta_image.png"},{"hid":"image","property":"image","name":"image","content":"/share_meta_visual.jpg"},{"hid":"og:image:type","property":"og:image:type","content":"image/png"},{"hid":"og:image","property":"og:image","content":"/share_meta_visual.jpg"},{"hid":"og:image","property":"og:image","name":"og:image","content":"/share_meta_visual.jpg"},{"hid":"og:site_name","property":"og:site_name","content":"ARTEX Global Markets"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:image:src","name":"twitter:image:src","content":"/share_meta_visual.jpg"},{"hid":"twitter:site","name":"twitter:site","content":"ARTEX Global Markets"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png?"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false