import { HttpLink, from } from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { sha256 } from 'crypto-hash';

export default defineNuxtPlugin((nuxtApp) => {
  // Get Nuxt runtimeConfig and apollo instance
  const runtimeConfig = useRuntimeConfig();
  const { $apollo } = useNuxtApp();

  const httpLink = createPersistedQueryLink({ sha256 }).concat(
    new HttpLink({
        uri: runtimeConfig.public.BACKEND_URL,
        credentials: 'include',
        useGETForQueries: true,
    }),
  );

  const errorLink = onError((err) => {
    nuxtApp.callHook('apollo:error', err);
  });

  // Set custom links in the apollo client (in this case, the default apollo client)
  $apollo.defaultClient.setLink(from([errorLink, httpLink]));

  nuxtApp.hook('apollo:error', (error) => {
    console.error(error);
  });
});

