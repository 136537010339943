import { useHead } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  useHead({
    script: [
      {
        src: 'https://consent.cookiebot.com/uc.js',
        id: 'Cookiebot',
        'data-cbid': '0872e0ab-2154-47f3-8b72-a45df5f647c1',
        async: true
      },
      {
        children: ` var waitForTrackerCount = 0;
          function matomoWaitForTracker() {
            if (typeof _paq === 'undefined' || typeof Cookiebot === 'undefined') {
              if (waitForTrackerCount < 40) {
                setTimeout(matomoWaitForTracker, 250);
                waitForTrackerCount++;
                return;
              }
            } else {
              window.addEventListener('CookiebotOnAccept', function (e) {
                  consentSet();
              });
              window.addEventListener('CookiebotOnDecline', function (e) {
                  consentSet();
              })
            }
          }
          function consentSet() {
            if (Cookiebot.consent.statistics) {
              _paq.push(['setCookieConsentGiven']);
              _paq.push(['setConsentGiven']);
            } else {
              _paq.push(['forgetCookieConsentGiven']);
              _paq.push(['forgetConsentGiven']);
            }
          }
          document.addEventListener('DOMContentLoaded', matomoWaitForTracker());`
      },
      {
        children: `var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['requireCookieConsent']);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://artexio.matomo.cloud/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '${config.public.matomoSiteId}']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='//cdn.matomo.cloud/artexio.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
          })();`
      },
      {
        src: 'https://consent.cookiebot.com/0872e0ab-2154-47f3-8b72-a45df5f647c1/cd.js',
        id: 'CookieDeclaration',
        async: true,
        tagPosition: 'bodyClose'
      }
    ]
  })
})
