import { default as indexjzKCSWqrznMeta } from "/app/www-artex-stockexchange-com-frontend/pages/artex-mtf/index.vue?macro=true";
import { default as index7RLEjVka2eMeta } from "/app/www-artex-stockexchange-com-frontend/pages/compliance/index.vue?macro=true";
import { default as index637mrZmZ19Meta } from "/app/www-artex-stockexchange-com-frontend/pages/contact/index.vue?macro=true";
import { default as indexa206nKq8b0Meta } from "/app/www-artex-stockexchange-com-frontend/pages/equities/index.vue?macro=true";
import { default as faqYykHf9l6VeMeta } from "/app/www-artex-stockexchange-com-frontend/pages/faq.vue?macro=true";
import { default as indexBaem4Pyjo9Meta } from "/app/www-artex-stockexchange-com-frontend/pages/how-to-list/index.vue?macro=true";
import { default as index2GznC9LQMKMeta } from "/app/www-artex-stockexchange-com-frontend/pages/index.vue?macro=true";
import { default as indexGOeVhDvHTOMeta } from "/app/www-artex-stockexchange-com-frontend/pages/news/index.vue?macro=true";
import { default as indexGQ7c5Gd1r4Meta } from "/app/www-artex-stockexchange-com-frontend/pages/notices/index.vue?macro=true";
import { default as indexi0xb5mOxe7Meta } from "/app/www-artex-stockexchange-com-frontend/pages/our-company/index.vue?macro=true";
import { default as privacy_45noticeFKjvbsaRFaMeta } from "/app/www-artex-stockexchange-com-frontend/pages/privacy-notice.vue?macro=true";
import { default as indexwaysbKvQ1cMeta } from "/app/www-artex-stockexchange-com-frontend/pages/reporting/index.vue?macro=true";
import { default as terms_45and_45conditionsDyzvn4hjEnMeta } from "/app/www-artex-stockexchange-com-frontend/pages/terms-and-conditions.vue?macro=true";
import { default as indexcoRMLN2bQIMeta } from "/app/www-artex-stockexchange-com-frontend/pages/trading-information/index.vue?macro=true";
import { default as indexJsu5nsGtsBMeta } from "/app/www-artex-stockexchange-com-frontend/pages/trading-participants/index.vue?macro=true";
import { default as indexMwFj0HhyvUMeta } from "/app/www-artex-stockexchange-com-frontend/pages/vcm/index.vue?macro=true";
export default [
  {
    name: indexjzKCSWqrznMeta?.name ?? "artex-mtf",
    path: indexjzKCSWqrznMeta?.path ?? "/artex-mtf",
    meta: indexjzKCSWqrznMeta || {},
    alias: indexjzKCSWqrznMeta?.alias || [],
    redirect: indexjzKCSWqrznMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/artex-mtf/index.vue").then(m => m.default || m)
  },
  {
    name: index7RLEjVka2eMeta?.name ?? "compliance",
    path: index7RLEjVka2eMeta?.path ?? "/compliance",
    meta: index7RLEjVka2eMeta || {},
    alias: index7RLEjVka2eMeta?.alias || [],
    redirect: index7RLEjVka2eMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/compliance/index.vue").then(m => m.default || m)
  },
  {
    name: index637mrZmZ19Meta?.name ?? "contact",
    path: index637mrZmZ19Meta?.path ?? "/contact",
    meta: index637mrZmZ19Meta || {},
    alias: index637mrZmZ19Meta?.alias || [],
    redirect: index637mrZmZ19Meta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexa206nKq8b0Meta?.name ?? "equities",
    path: indexa206nKq8b0Meta?.path ?? "/equities",
    meta: indexa206nKq8b0Meta || {},
    alias: indexa206nKq8b0Meta?.alias || [],
    redirect: indexa206nKq8b0Meta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/equities/index.vue").then(m => m.default || m)
  },
  {
    name: faqYykHf9l6VeMeta?.name ?? "faq",
    path: faqYykHf9l6VeMeta?.path ?? "/faq",
    meta: faqYykHf9l6VeMeta || {},
    alias: faqYykHf9l6VeMeta?.alias || [],
    redirect: faqYykHf9l6VeMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexBaem4Pyjo9Meta?.name ?? "how-to-list",
    path: indexBaem4Pyjo9Meta?.path ?? "/how-to-list",
    meta: indexBaem4Pyjo9Meta || {},
    alias: indexBaem4Pyjo9Meta?.alias || [],
    redirect: indexBaem4Pyjo9Meta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/how-to-list/index.vue").then(m => m.default || m)
  },
  {
    name: index2GznC9LQMKMeta?.name ?? "index",
    path: index2GznC9LQMKMeta?.path ?? "/",
    meta: index2GznC9LQMKMeta || {},
    alias: index2GznC9LQMKMeta?.alias || [],
    redirect: index2GznC9LQMKMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGOeVhDvHTOMeta?.name ?? "news",
    path: indexGOeVhDvHTOMeta?.path ?? "/news",
    meta: indexGOeVhDvHTOMeta || {},
    alias: indexGOeVhDvHTOMeta?.alias || [],
    redirect: indexGOeVhDvHTOMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexGQ7c5Gd1r4Meta?.name ?? "notices",
    path: indexGQ7c5Gd1r4Meta?.path ?? "/notices",
    meta: indexGQ7c5Gd1r4Meta || {},
    alias: indexGQ7c5Gd1r4Meta?.alias || [],
    redirect: indexGQ7c5Gd1r4Meta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/notices/index.vue").then(m => m.default || m)
  },
  {
    name: indexi0xb5mOxe7Meta?.name ?? "our-company",
    path: indexi0xb5mOxe7Meta?.path ?? "/our-company",
    meta: indexi0xb5mOxe7Meta || {},
    alias: indexi0xb5mOxe7Meta?.alias || [],
    redirect: indexi0xb5mOxe7Meta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/our-company/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45noticeFKjvbsaRFaMeta?.name ?? "privacy-notice",
    path: privacy_45noticeFKjvbsaRFaMeta?.path ?? "/privacy-notice",
    meta: privacy_45noticeFKjvbsaRFaMeta || {},
    alias: privacy_45noticeFKjvbsaRFaMeta?.alias || [],
    redirect: privacy_45noticeFKjvbsaRFaMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: indexwaysbKvQ1cMeta?.name ?? "reporting",
    path: indexwaysbKvQ1cMeta?.path ?? "/reporting",
    meta: indexwaysbKvQ1cMeta || {},
    alias: indexwaysbKvQ1cMeta?.alias || [],
    redirect: indexwaysbKvQ1cMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/reporting/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsDyzvn4hjEnMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsDyzvn4hjEnMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsDyzvn4hjEnMeta || {},
    alias: terms_45and_45conditionsDyzvn4hjEnMeta?.alias || [],
    redirect: terms_45and_45conditionsDyzvn4hjEnMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: indexcoRMLN2bQIMeta?.name ?? "trading-information",
    path: indexcoRMLN2bQIMeta?.path ?? "/trading-information",
    meta: indexcoRMLN2bQIMeta || {},
    alias: indexcoRMLN2bQIMeta?.alias || [],
    redirect: indexcoRMLN2bQIMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/trading-information/index.vue").then(m => m.default || m)
  },
  {
    name: indexJsu5nsGtsBMeta?.name ?? "trading-participants",
    path: indexJsu5nsGtsBMeta?.path ?? "/trading-participants",
    meta: indexJsu5nsGtsBMeta || {},
    alias: indexJsu5nsGtsBMeta?.alias || [],
    redirect: indexJsu5nsGtsBMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/trading-participants/index.vue").then(m => m.default || m)
  },
  {
    name: indexMwFj0HhyvUMeta?.name ?? "vcm",
    path: indexMwFj0HhyvUMeta?.path ?? "/vcm",
    meta: indexMwFj0HhyvUMeta || {},
    alias: indexMwFj0HhyvUMeta?.alias || [],
    redirect: indexMwFj0HhyvUMeta?.redirect,
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/vcm/index.vue").then(m => m.default || m)
  }
]